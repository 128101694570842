import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import RadioChannelsList from '../components/RadioChannelsList';
import $ from 'jquery';

class Radio extends Component {
    componentDidMount() {
        $(document).find('.radio-player').removeClass('lock');
        function checkRadioPlaying(){
            let audioElem = $(document).find('#audioElem video');
            if (!audioElem.paused) {
                let getPlayingChannel = $(document).find('#audioElem').attr("data-uri");
                $(document).find('.radio-channels li').each(function(){
                    let getCurrentUri = $(this).attr('data-uri');
                    if(getCurrentUri === getPlayingChannel) {
                        $(this).parent().find('li').removeClass('active');
                        $(this).addClass('active');
                        return false;
                    }
                });
            }
        }
        let RadioPlayerDiv = $(document).find('.radio-player');
        if($(RadioPlayerDiv).is(':visible')) {
            checkRadioPlaying();
        }

        /*****javascript keyboard events*****/
        $(document).on("keydown", function (event) {
            if (event.keyCode === 37) {
                // console.log('left arrow pressed');
                $(document).find('.radio-player #prevBtn').trigger('click');
            }
            if (event.keyCode === 38) {
                // console.log('up arrow pressed');
            }
            if (event.keyCode === 39) {
                // console.log('right arrow pressed');
                $(document).find('.radio-player #nextBtn').trigger('click');
            }
            if (event.keyCode === 40) {
                // console.log('down arrow pressed');
            }
        });
        
    }
    componentWillUnmount(){
        $(document).find('.radio-player').addClass('lock');
        let RadioPlayerDiv = $(document).find('.radio-player');
        if($(RadioPlayerDiv).is(':visible')) {
            $('body').addClass('radio-miniplayer');
            $('body').removeClass('fullscreen-player');
        }
    }
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                    <RadioChannelsList />
                </div>
            </div>
        );
    }
}

export default Radio;