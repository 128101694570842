import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import TvChannelsList from '../components/TvChannelsList';
import $ from 'jquery';

class LiveTv extends Component {
    componentDidMount() {
        function checkTvPlaying(){
            let audioElem = $(document).find('#audioElem video');
            if (!audioElem.paused) {
                let getPlayingChannel = $(document).find('#channelName').attr("data-id");
                $(document).find('.tv-channels li').each(function(){
                    let getCurrentUri = $(this).attr('data-uri');
                    if(getCurrentUri === getPlayingChannel) {
                        $(this).parent().find('li').removeClass('active');
                        $(this).addClass('active');
                        return false;
                    }
                });
            }
        }
        let TvPlayerDiv = $(document).find('.tvplayer');
        if($(TvPlayerDiv).is(':visible')) {
            checkTvPlaying();
        }
    }
    componentWillUnmount(){
        let RadioPlayerDiv = $(document).find('.tvplayer');
        if($(RadioPlayerDiv).is(':visible')) {
            $('body').addClass('tv-miniplayer');
            $('body').removeClass('fullscreen-player');
        }
    }
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                    <TvChannelsList />
                </div>
            </div>
        );
    }
}

export default LiveTv;