import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import GamesList from '../components/GamesList';
import '../assets/css/game.css';

class Games extends Component {
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                    <GamesList />
                </div>
            </div>
        );
    }
}

export default Games;