import React from 'react';
import logo from '../assets/images/jhankarplay-logo.png';

function LoaderBackground(){
    return (
        <div className="bgloader">
            <div className="area">
                <div className="loader-logo">
                <img src={logo} alt="Logo" />
                </div>
                <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                </ul>
            </div>
        </div>
    );
}

export default LoaderBackground;