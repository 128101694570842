import React,{useState} from 'react';
import '../assets/css/tvplayer.css';
import '../../node_modules/jquery-touchswipe/jquery.touchSwipe.js';
import { MdOutlineExpandMore,MdExpandLess,MdFullscreen,MdFullscreenExit } from "react-icons/md";
import { MdPlayCircle,MdPauseCircle,MdOutlineClose } from "react-icons/md";
import ReactHlsPlayer from "react-hls-player";
import $ from 'jquery';
import bgLayer from '../assets/images/radio/thumbnail.jpg';

function TvPlayer() {
    const [hlsUrl, setHlsUrl] = useState();

    const playerRef = React.useRef();
    function playVideo() {
        playerRef.current.play();
    }
    function pauseVideo() {
        playerRef.current.pause();
    }
    
    $(document).on('click','.tvplayer .full-icon', function(){
        $('body').addClass('fullscreen-player');
    });
    $(document).on('click','.tvplayer .exit-full-icon', function(){
        $('body').removeClass('fullscreen-player');
    });

    function togglePlay(){
        let getUrlSource = $(document).find('#channelName').attr('data-id');
        setHlsUrl(getUrlSource);

        $('.playerframe .controls').fadeIn();
        $('.controls .play-btn').toggleClass('active');
        if(!($('.controls .play-btn').hasClass('active'))) {
            pauseVideo();
        } else {
            playVideo();
        }
        setTimeout(function(){
            $('.playerframe .controls').fadeOut();
        }, 3000);
    }

    $(document).on('click','.tv-channels li', function(event){
        event.stopImmediatePropagation();
        $(this).parent().find('li').removeClass('active');
        $(this).addClass('active');
        let channel_uri = $(this).attr('data-uri');
        let channel_name = $(this).attr('data-channel');
        tvChannelPlayer(channel_uri,channel_name);
        checkRadioisPlaying();
    });

    function tvChannelPlayer(channel_uri,channel_name){
        document.title = channel_name +" Playing || Jhankar Play";
        $(document).find('.tvplayer').removeClass('left-closed right-closed');
        $(document).find('.tvplayer').show();
        $('#channelName').text(channel_name);
        $('#channelName').attr('data-id',channel_uri);
        setTimeout(function(){
            pauseVideo();
            togglePlay();
        }, 1500);
    }

    $(document).on('click','.tvplayer .player-maximize-btn', function(){
        $('body').removeClass('tv-miniplayer');
    });
    $(document).on('click','.tvplayer .back-icon', function(){
        $('body').addClass('tv-miniplayer');
    });

    function closeCurrentPlayer(direction){
        if($('body').hasClass('tv-miniplayer')) {
            pauseVideo();
            if(direction === "left") {
                $(document).find('.tvplayer').addClass('left-closed');
            } else {
                $(document).find('.tvplayer').addClass('right-closed');
            }
            setTimeout(function(){
                $(document).find('.tvplayer').hide();
                $(document).find('.tv-channels li').removeClass('active');
                $(document).find('body').removeClass('tv-miniplayer');
            }, 1500);
        }
    }

    $(".tvplayer").swipe({
        //Single swipe handler for left swipes
        swipeLeft:function(event) {
            closeCurrentPlayer('left');
        },
        swipeRight:function(event) {
            closeCurrentPlayer('right');
        },
        swipeUp:function(event) {
            if($('body').hasClass('tv-miniplayer')) {
                $(document).find('body').removeClass('tv-miniplayer');
            }
        },
        swipeDown:function(event) {
            if(!($('body').hasClass('tv-miniplayer'))) {
                $(document).find('body').addClass('tv-miniplayer');
            }
        }
    });

    $(document).on('click','#closeTvPlayer', function(){
        closeCurrentPlayer('left');
    });

    /*********checking radio is playing or not**********/
    function checkRadioisPlaying() {
        let audioElem = $(document).find('#audioElem video');
        $(audioElem).trigger('pause');
        $(document).find('.radio-player').addClass('left-closed');
        setTimeout(function(){
            $(document).find('.radio-player').hide();
            $('body').removeClass('radio-miniplayer');
            $('.radio-channels li').removeClass('active');
        }, 900);
    }

    return (
        <div className="tvplayer">
            <div className="background-layer">
                <img src={bgLayer} alt="Background" />
            </div>
            <div className="player-title">
                <div className="back-icon">
                    <MdOutlineExpandMore />
                </div>
                <h1 id="channelName" data-id="0">Channel Name</h1>
                <div className="fullscreen-icon">
                    <i className="full-icon">
                        <MdFullscreen />
                    </i>
                    <i className="exit-full-icon displayhide">
                        <MdFullscreenExit />
                    </i>
                </div>
            </div>
            <div className="player-content">
                <div className="playerframe">
                    <span className="maximize-icon player-maximize-btn">
                        <MdExpandLess />
                    </span>
                    <span className="fas displayhide player-close" id="closeTvPlayer">
                        <MdOutlineClose />
                    </span>
                    <ReactHlsPlayer
                        playerRef={playerRef}
                        autoPlay={true}
                        controls={false}
                        src={hlsUrl}
                        hlsConfig={{
                            maxLoadingDelay: 4,
                            minAutoBitrate: 0,
                            lowLatencyMode: true,
                        }}
                        onClick={togglePlay}
                    />
                    <div className="controls">
                        <div className="play-btn" onClick={togglePlay}>
                            <span className="play-icon"><MdPlayCircle /></span>
                            <span className="pause-icon"><MdPauseCircle /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TvPlayer;