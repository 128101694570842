const RadioChannels = [
    { 
        id: 1, 
        category: 'music',
        channel_name: 'Radio Junction', 
        channel_link: 'https://prclive1.listenon.in/Hindi',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 2,
        category: 'music',
        channel_name: 'Radio Baingan', 
        channel_link: 'https://stream.zeno.fm/eyxg23ky4x8uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 3, 
        category: 'music',
        channel_name: 'My Radio DJ', 
        channel_link: 'https://node-19.zeno.fm/84h97t3ewg0uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 4,
        category: 'music',
        channel_name: 'Panga Radio', 
        channel_link: 'https://stream.zeno.fm/irbnwvhopx0uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 5, 
        category: 'music',
        channel_name: 'Radio 24', 
        channel_link: 'https://us.everestcast.com:1150/stream',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 6, 
        category: 'music',
        channel_name: 'Radio Ujala', 
        channel_link: 'https://stream2.ujala.nl/stream/2/listen.mp3',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 7, 
        category: 'music',
        channel_name: 'Sayaji FM', 
        channel_link: 'https://sayajifm.radioca.st/stream',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 8, 
        category: 'music',
        channel_name: 'Radio Mantra', 
        channel_link: 'https://streamlky.alsolnet.com/mantrafm',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 9, 
        category: 'music',
        channel_name: 'Radio Mirchi 98.3', 
        channel_link: 'https://securestreams8.autopo.st:3003/1',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 10, 
        category: 'music',
        channel_name: 'Radio Antara', 
        channel_link: 'https://node-12.zeno.fm/x6ch94sse0quv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 11, 
        category: 'music',
        channel_name: 'Bollywood Purane Gaane', 
        channel_link: 'https://stream.zeno.fm/6n6ewddtad0uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 12, 
        category: 'music',
        channel_name: 'Radio Umang', 
        channel_link: 'https://cors.bitwize.com.lb/http://vmi352552.contaboserver.net/radio/8000/radio.mp3',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 13, 
        category: 'music',
        channel_name: 'Gurgaon Ki Awaaz', 
        channel_link: 'https://gurgaonradio.out.airtime.pro/gurgaonradio_b',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 14, 
        category: 'music',
        channel_name: 'Deoghar FM', 
        channel_link: 'https://edudew.com:8443/edudew',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 15, 
        category: 'music',
        channel_name: 'Radio Nashaa FM', 
        channel_link: 'https://streamer.radio.co/seb6c7f6b0/listen',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 16, 
        category: 'music',
        channel_name: 'Radio Dhol', 
        channel_link: 'https://gill.sukhpal.net:4432/radio/8000/radio.mp3',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 17, 
        category: 'bhakti',
        channel_name: 'Bhakti Sudha Pune', 
        channel_link: 'https://n02.radiojar.com/cfqyfcspcv8uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 18,
        category: 'bhakti',
        channel_name: 'Radio Sangam Bhakti', 
        channel_link: 'https://iskon2b.out.airtime.pro:8000/iskon2b_a',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 19, 
        category: 'music',
        channel_name: 'Bombay Beats Radio', 
        channel_link: 'https://strmreg.1.fm/bombaybeats_mobile_mp3',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 20,
        category: 'music',
        channel_name: 'My Club Rimix', 
        channel_link: 'https://stream.zeno.fm/pkvh8zhyzp8uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 21,
        category: 'gujarati',
        channel_name: 'Goldy Gujarat', 
        channel_link: 'https://stream.zeno.fm/28wseh18am8uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 22,
        category: 'bhakti',
        channel_name: 'Bhakti Sangeet', 
        channel_link: 'https://stream.zeno.fm/r51n9k1vsa0uv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 23,
        category: 'music',
        channel_name: 'Gaana Radio', 
        channel_link: 'https://stream.zeno.fm/szh14bya1feuv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
    { 
        id: 24,
        category: 'music',
        channel_name: 'Radio Madhuban', 
        channel_link: 'https://stream.zeno.fm/1t7pmqcm9hhvv',
        thumbnail: 'assets/images/radio/thumbnail.jpg'
    },
];

export default RadioChannels;