import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './assets/css/style.css';
import './assets/css/responsive.css';
import $ from 'jquery';

import Navigation from './components/Navigation';
import RadioPlayer from './components/RadioPlayer';
import TvPlayer from './components/TvPlayer';
import Home from './pages/Home';
import LiveTv from './pages/LiveTv';
import Radio from './pages/Radio';
import Games from './pages/Games';
import Contact from './pages/Contact';
import VideoPlayer from './components/VideoPlayer';
import Disclaimer from './pages/Disclaimer';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GamePlayer from './components/GamePlayer';
import Search from './pages/Search';

class App extends Component {
  componentDidMount() {
    setTimeout(function(){
      $(document).find('html').addClass('loaded');
    }, 2000);
  }
  render() {
    return (
      <BrowserRouter basename="/app/">
        <div className="app">
          <Navigation />
          <div className="app-content">
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="livetv" element={<LiveTv />} />
              <Route path="radio" element={<Radio />} />
              <Route path="games" element={<Games />} />
              <Route path="contact" element={<Contact />} />
              {/* <Route path="watch" element={<VideoPlayer />} /> */}
              <Route path="disclaimer" element={<Disclaimer />} />
              <Route path="terms_conditions" element={<TermsAndConditions />} />
              <Route path="privacy_policy" element={<PrivacyPolicy />} />
              <Route path="game/player" element={<GamePlayer />} />
              <Route path="search" element={<Search />} />
            </Routes>
          </div>
          <RadioPlayer />
          <TvPlayer />
          <VideoPlayer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;