const baseUrl = "https://jhankarplay.com/app/components/games/";
const gameList = [
    { 
        id: 1,
        game_name: 'Menja 2.0', 
        game_uri: 'menja',
        game_link: baseUrl+'menja.php'
    },
    { 
        id: 2,
        game_name: 'Tic Tac', 
        game_uri: 'tic-tac',
        game_link: baseUrl+'tic-tac.php'
    },
    { 
        id: 3,
        game_name: 'Phaser', 
        game_uri: 'phaser',
        game_link: baseUrl+'phaser.php'
    },
    { 
        id: 4,
        game_name: 'Santa Run', 
        game_uri: 'santa-run',
        game_link: baseUrl+'santa-run.php'
    },
    { 
        id: 5,
        game_name: 'Car Racing', 
        game_uri: 'car-racing',
        game_link: 'https://media.boyslife.org/onlinegames/nitroderby/'
    },
    { 
        id: 6,
        game_name: 'Bike Racing', 
        game_uri: 'bike-racing',
        game_link: 'https://media.scoutlife.org/onlinegames/nitroderby/'
    }
];

export default gameList;