import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import '../assets/css/contact.css';
import { FaMapMarkerAlt,FaEnvelope } from "react-icons/fa";
import { BsFacebook,BsInstagram,BsYoutube,BsGlobe2 } from "react-icons/bs";

class Contact extends Component {
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                <div className="terms-box text-muted pt-0 pb-5">
                    <div className="row">
                        <div className="contact-map">
                            <iframe title="Contact Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.8566184159554!2d82.15850411474389!3d26.68506777627763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a094bc15931f7%3A0x6c92ee4a1d1024d2!2sJhankar%20Play!5e0!3m2!1sen!2sin!4v1646455978940!5m2!1sen!2sin" width="100%" height="380" allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className="contact-wrapper">
                        <div className="direct-contact-container">
                            <ul className="contact-list">
                                <li className="list-item">
                                    <span className="fa">
                                        <FaMapMarkerAlt />
                                    </span>
                                    <span className="contact-text place">Ayodhya, Utter Pradesh,<br /> India - 224001</span>
                                </li>
                                <li className="list-item">
                                    <span className="fa">
                                        <FaEnvelope />
                                    </span>
                                    <span className="contact-text gmail">
                                        <a href="mailto:info@jhankarplay.com" title="Send me an email">info@jhankarplay.com</a>
                                    </span>
                                </li>
                            </ul>
                            <hr/>
                            <ul className="social-media-list">
                                <li>
                                    <a href="https://www.facebook.com/vkgupta704" target="_blank" rel="noreferrer" className="contact-icon">
                                        <BsFacebook  />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/vkgupta704" target="_blank" rel="noreferrer" className="contact-icon">
                                        <BsInstagram />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/mistervinay" target="_blank" rel="noreferrer" className="contact-icon">
                                        <BsYoutube />
                                    </a>
                                </li>
                                <li>
                                    <a href="http://jhankarplay.com" target="_blank" rel="noreferrer" className="contact-icon">
                                        <BsGlobe2 />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Contact;