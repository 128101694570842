import React from 'react';
import {Link} from 'react-router-dom';
import { MdOutlinePlayCircle,MdPauseCircleOutline } from "react-icons/md";
import DefaulCardImage from '../assets/images/radio/thumbnail.jpg';
import GameList from '../api/GameList';

function GamesItemList(props) {
    const ListItems = props.GameList.map((element) =>
        <li key={element.id} data-chennel={element.game_name}>
            <Link to={"/game/player?name="+element.game_uri} className="grid-list">
                <div className="thumb-icon">
                    <img src={DefaulCardImage} alt={element.game_name} />
                </div>
                <h4>{element.game_name}</h4>
                <div className="play-icon">
                    <div className="play-circle">
                        <MdOutlinePlayCircle />
                    </div>
                    <div className="pause-circle displayhide">
                        <MdPauseCircleOutline />
                    </div>
                </div>
            </Link>
        </li>
    );
    return(
        <ul className="channel-list game-list">
            {ListItems}
        </ul>
    )
}

function GamesList(){
    return (
        <div>
            <GamesItemList GameList={GameList} />
        </div>
    );
}

export default GamesList;