import React from 'react';
import { MdOutlinePlayCircle,MdPauseCircleOutline } from "react-icons/md";
import DefaulCardImage from '../assets/images/background.jpg';
import RadioChannels from '../api/RadioChannels';

function RadioList(props) {
    const ListItems = props.RadioChannels.map((element) =>
        <li key={element.id} data-uri={element.channel_link} data-id={element.id} data-chennel={element.channel_name}>
            <div className="grid-list">
                <div className="thumb-icon">
                    <img src={DefaulCardImage} alt={element.channel_name} />
                </div>
                <div className="channel-text">
                    <h4>{element.channel_name}</h4>
                    <span>{element.category}</span>
                </div>
                <div className="play-icon">
                    <div className="play-circle">
                        <MdOutlinePlayCircle />
                    </div>
                    <div className="pause-circle displayhide">
                        <MdPauseCircleOutline />
                    </div>
                </div>
            </div>
        </li>
    );
    return(
        <ul className="channel-list radio-channels">
            {ListItems}
        </ul>
    )
}

function RadioChannelsList(){
    return (
        <div>
            <RadioList RadioChannels={RadioChannels} />
        </div>
    );
}

export default RadioChannelsList;