import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import HomeCardSlider from '../components/HomeCardSlider';

class Home extends Component {
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                    <HomeCardSlider />
                </div>
            </div>
        );
    }
}

export default Home;