import React, {useState,useEffect} from 'react';
import { MdOutlinePlayCircle,MdPauseCircleOutline } from "react-icons/md";
import DefaulCardImage from '../assets/images/radio/thumbnail.jpg';
import TvChannels from '../api/TvChannels';
import $ from 'jquery';

function ChannelList(props) {
    // const channelCategory = props.category;

    const ListItems = props.TvChannels
    // .filter(element => element.category !== channelCategory)
    .map((element) =>
        <li key={element.id} data-category={element.category} data-uri={element.channel_link} data-channel={element.channel_name}>
            <div className="grid-list">
                <div className="thumb-icon">
                    <img src={DefaulCardImage} alt={element.channel_name} />
                </div>
                <h4>{element.channel_name}</h4>
                <div className="play-icon">
                    <div className="play-circle">
                        <MdOutlinePlayCircle />
                    </div>
                    <div className="pause-circle displayhide">
                        <MdPauseCircleOutline />
                    </div>
                </div>
            </div>
        </li>
    );
    return(
        <ul className="channel-list channel-grid tv-channels">
            {ListItems}
        </ul>
    )
}

function TvChannelsList(){
    const [channelCategory, setChannelCategory] = useState(null);
    useEffect(() => {
        const getCurrentUrl = window.location.search;
        const getParams = new URLSearchParams(getCurrentUrl);
        const urlcategory = getParams.get('category');
        if(urlcategory === "" || urlcategory === undefined || urlcategory === null) {
            setChannelCategory("all");
            $(document).find('.tv-channels li').show();
        } else {
            setChannelCategory(urlcategory);
            $(document).find('.tv-channels li[data-category="'+urlcategory+'"]').show();
        }
    },[]);

    return (
        <div>
            <ChannelList TvChannels={TvChannels} category={channelCategory} />
        </div>
    );
}

export default TvChannelsList;