import React,{useEffect} from 'react';
import '../assets/css/tvplayer.css';
// import { MdExpandLess } from "react-icons/md";
import $ from 'jquery';
const apikey = "AIzaSyCXR8LcHgTEJ2s4NRthNcr97obRYTYz_Ds";

$(document).on('click','.tvplayer .fullscreen-icon', function(){
    $('body').toggleClass('fullscreen-player');
});

function SearchResult() {
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const searchkey = queryParams.get("key");
        $(document).find('#searchPageKey').val(searchkey);
        videoSearch(searchkey);
    });

    $(document).on('input focus','#searchPageKey', function(){
        let checkValue = $(this).val();
        if(!(checkValue === "")) {
            $(document).find('.search-key-list').show();
        } else {
            $(document).find('.search-key-list').hide();
        }
    });
    
    // $(document).on('blur','#searchPageKey', function(){
    //     $(document).find('.search-key-list').hide();
    // });

    const handleSearchKey = (event) => {
        let searchKeyNew = event.target.value;
        if (event.key === 'Enter') {
            videoSearch(searchKeyNew);
            event.currentTarget.blur();
        } else {
            $.ajax({
                type: "POST",
                url: 'https://suggestqueries.google.com/complete/search?hl=en&ds=yt&client=youtube&hjson=t&cp=1',
                dataType: 'jsonp',
                data: $.extend({
                    q: searchKeyNew
                }, {}),
                success: function (data) {
                    // console.log(data);
                    let items = data[1], videoKeyList = "";
                    $.each(items, function (index, element) {
                        // console.log(index);
                        videoKeyList = videoKeyList + '<li data-key="'+element[0]+'">'+element[0]+'</li>';
                    });
                    $("#SearchKeyList").html(videoKeyList);
                }
            });
        }
    }

    $(document).on('click','.search-key-list li', function(){
        let getKeyData = $(this).attr('data-key');
        $(document).find('#searchPageKey').val(getKeyData);
        videoSearch(getKeyData);
        $(document).find('.search-key-list').hide();
    });

    // $('.search-key-list li').click(function() {
    //     alert();
    //     let getKeyData = $(this).attr('data-key');
    //     console.log(getKeyData);
    //     $(document).find('#searchPageKey').val(getKeyData);
    //     videoSearch(getKeyData);
    // });

    const videoSearch = (term) => {
        // console.log(term);
        $.ajax({
            cache: false,
            data: $.extend({
                key: apikey,
                q: term,
                part: 'snippet',
                safeSearch: 'strict',
                type: 'video',
            }, 
            {
                maxResults: 14, 
                // pageToken: $("#pageToken").val()
            }),
            dataType: 'json',
            type: 'GET',
            timeout: 5000,
            url: 'https://www.googleapis.com/youtube/v3/search'
        })
        .done(function (data) {
            // console.log(data);
            let items = data.items, videoList = "";
            $.each(items, function (index, e) {
                videoList = videoList + '<li data-id="' + e.id.videoId + '" data-title="' + e.snippet.title + '" data-source="search"><img src="' + e.snippet.thumbnails.high.url + '" alt="Youtube Videos"/><p>' + e.snippet.title + '</p></li>';
            });
            $("#VideosList").html(videoList);
        });
    }

    return (
        <>
            <div className="search-area">
                <input type="search" name="search" id="searchPageKey" placeholder="Search..." autoComplete="off" onKeyDown={handleSearchKey} />
                <ul className="search-key-list" id="SearchKeyList"></ul>
            </div>
            <div className="video-list">
                <ul id="VideosList"></ul>
            </div>
        </>
    );
}

export default SearchResult;