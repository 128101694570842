import React from "react";
import '../assets/css/radioplayer.css';
import '../../node_modules/jquery-touchswipe/jquery.touchSwipe.js';
import { FaRandom,FaRedo } from "react-icons/fa";
import { MdOutlineExpandMore,MdOutlineMoreVert,MdSkipPrevious,MdSkipNext,MdOutlineClose } from "react-icons/md";
import { MdPlayCircle,MdPauseCircle,MdVolumeDown,MdVolumeUp,MdExpandLess } from "react-icons/md";
import $ from 'jquery';
// import bgLayer from '../assets/images/radio/thumbnail.jpg';
import bgLayer from '../assets/images/background.jpg';
import ReactPlayer from "react-player";

function RadioPlayer(){

    function loadRadioByURL(){
        const getCurrentUrl = window.location.href;
        $(".radio-channels li").each(function(){
            let getCurrId = $(this).attr('data-id');
            if(getCurrentUrl.indexOf(getCurrId)) {
                let channel_name = $(this).find('h4').text();
                let channel_id = $(this).attr('data-id');
                let channel_uri = $(this).attr('data-uri');
                let channel_next_uri = $(this).next().attr('data-uri');
                let channel_prev_uri = $(this).prev().attr('data-uri');
                radioChannelPlayer(channel_name,channel_uri,channel_next_uri,channel_prev_uri,channel_id);
                return false;
            }
        });
    }

    loadRadioByURL();
    
    $(document).on('click','.radio-player .player-maximize-btn', function(){
        $('body').removeClass('radio-miniplayer');
    });
    $(document).on('click','.radio-player .back-icon', function(){
        $('body').addClass('radio-miniplayer');
    });

    $(document).on('click','.radio-channels li', function(event){
        event.stopImmediatePropagation();
        $(this).parent().find('li').removeClass('active');
        $(this).addClass('active');
        let channel_name = $(this).find('h4').text();
        let channel_id = $(this).attr('data-id');
        let channel_uri = $(this).attr('data-uri');
        let channel_next_uri = $(this).next().attr('data-uri');
        let channel_prev_uri = $(this).prev().attr('data-uri');
        radioChannelPlayer(channel_name,channel_uri,channel_next_uri,channel_prev_uri,channel_id);
    });

    function radioChannelPlayer(channel_name,channel_uri,channel_next_uri,channel_prev_uri,channel_id){
        document.title = channel_name +" Playing || Jhankar Play";
        $(document).find('.radio-player').removeClass('left-closed right-closed');
        $(document).find('.radio-player').show();
        window.history.pushState('radio', 'Title', '/app/radio?channel='+channel_id);
        $('#channelName').html(channel_name);
        $('#audioElem').attr("url",channel_uri);
        $('#audioElem video').attr("src",channel_uri);
        $('#audioElem').attr("data-uri",channel_uri);
        $('#audioElem').attr("data-next-uri",channel_next_uri);
        $('#audioElem').attr("data-prev-uri",channel_prev_uri);
        $(document).find('.radio-player .song-info-artist').text(channel_name);
        $(document).find('#playPauseBtn').addClass('active');
        let audioElem = $(document).find('#audioElem video');
        $(audioElem).trigger('play');
        $(document).find('#music').addClass('now');
    }

    $(document).on('click','.radio-player .player-state-btn', function(event){
        event.stopImmediatePropagation();
        let audioElem = $(document).find('#audioElem video');
        $(this).toggleClass('active');
        if($(this).hasClass('active')) {
            $(audioElem).trigger('play');
            $(document).find('#playPauseBtn').addClass('active');
            $(document).find('#music').addClass('now');
        } else {
            $(audioElem).trigger('pause');
            $(document).find('#playPauseBtn').removeClass('active');
            $(document).find('#music').removeClass('now');
        }
    });

    $(document).on('click','.radio-player #prevBtn', function(event){
        event.stopImmediatePropagation();
        let getPrevChannel = $(document).find('#audioElem').attr('data-prev-uri');
        $('.radio-channels li').each(function(){
            let getCurrentUri = $(this).attr('data-uri');
            if(getCurrentUri === getPrevChannel) {
                $(this).parent().find('li').removeClass('active');
                $(this).addClass('active');
                let channel_id = $(this).attr('data-id');
                let current_channel_name = $(this).find('h4').text();
                let channel_next_uri = $(this).next().attr('data-uri');
                let channel_prev_uri = $(this).prev().attr('data-uri');
                $(document).find('#audioElem').attr("data-next-uri",channel_next_uri);
                $(document).find('#audioElem').attr("data-prev-uri",channel_prev_uri);
                radioChannelPlayer(current_channel_name,getPrevChannel,channel_next_uri,channel_prev_uri,channel_id);
                return false;
            }
        });
    });
    
    $(document).on('click','.radio-player #nextBtn', function(event){
        event.stopImmediatePropagation();
        let getNextChannel = $(document).find('#audioElem').attr('data-next-uri');
        $('.radio-channels li').each(function(){
            let getCurrentUri = $(this).attr('data-uri');
            if(getCurrentUri === getNextChannel) {
                $(this).parent().find('li').removeClass('active');
                $(this).addClass('active');
                let channel_id = $(this).attr('data-id');
                let current_channel_name = $(this).find('h4').text();
                let channel_next_uri = $(this).next().attr('data-uri');
                let channel_prev_uri = $(this).prev().attr('data-uri');
                $(document).find('#audioElem').attr("data-next-uri",channel_next_uri);
                $(document).find('#audioElem').attr("data-prev-uri",channel_prev_uri);
                radioChannelPlayer(current_channel_name,getNextChannel,channel_next_uri,channel_prev_uri,channel_id);
                return false;
            }
        });
    });

    $(document).on('click','.radio-player .valume-off', function(event){
        event.stopImmediatePropagation();
        let audioElem = $(document).find('#audioElem video');
        $(audioElem).prop("volume", 0);
        $('.volume-bar .volume-trail').css('width','0%');
    });
    $(document).on('click','.radio-player .valume-on', function(event){
        event.stopImmediatePropagation();
        let audioElem = $(document).find('#audioElem video');
        $(audioElem).prop("volume", 1);
        $('.volume-bar .volume-trail').css('width','100%');
    });

    function closeCurrentPlayer(direction){
        if($('body').hasClass('radio-miniplayer')) {
            let audioElem = $(document).find('#audioElem video');
            $(audioElem).trigger('pause');
            if(direction === "left") {
                $(document).find('.radio-player').addClass('left-closed');
            } else {
                $(document).find('.radio-player').addClass('right-closed');
            }
            setTimeout(function(){
                $(document).find('.radio-player').hide();
                $('body').removeClass('radio-miniplayer');
                $('.radio-channels li').removeClass('active');
            }, 900);
        }
    }

    $(document).on('click','.radio-player .player-close', function(){
        closeCurrentPlayer('left');
    });

    $(".radio-player").swipe({
        //Single swipe handler for left swipes
        swipeLeft:function(event) {
            closeCurrentPlayer('left');
        },
        swipeRight:function(event) {
            closeCurrentPlayer('right');
        },
        swipeUp:function(event) {
            if($('body').hasClass('radio-miniplayer')) {
                $(document).find('body').removeClass('radio-miniplayer');
            }
        },
        swipeDown:function(event) {
            if(!($('body').hasClass('radio-miniplayer'))) {
                $(document).find('body').addClass('radio-miniplayer');
            }
        }
    });

    return (
        <div className="radio-player">
            <div className="background-layer">
                <img src={bgLayer} alt="Background" />
            </div>
            <div className="radio-box">
                <div className="header">
                    <div className="back-icon">
                        <MdOutlineExpandMore />
                    </div>
                    <div className="moremenu-icon">
                        <MdOutlineMoreVert />
                    </div>
                </div>
                <div className="radio-controls-box">
                    <div className="song-wrapper">
                    <ReactPlayer
                        autoPlay 
                        data-uri="" 
                        data-prev-uri="" 
                        data-next-uri="" 
                        id="audioElem"
                        url="https://prclive1.listenon.in/Hindi"
                        playing={false}
                        controls={true}
                        hidden
                    />
                        {/* <audio autoPlay src="" data-uri="" data-prev-uri="" data-next-uri="" id="audioElem" preload="false" hidden></audio> */}
                        <div className="song-thumb-wrapper">
                            <div className="song-thumb"></div>
                        </div>
                        <div className="playing" id="music">
                            <span className="bar n1">&nbsp;</span>
                            <span className="bar n2">&nbsp;</span>
                            <span className="bar n3">&nbsp;</span>
                            <span className="bar n4">&nbsp;</span>
                            <span className="bar n5">&nbsp;</span>
                            <span className="bar n6">&nbsp;</span>
                            <span className="bar n7">&nbsp;</span>
                            <span className="bar n8">&nbsp;</span>
                        </div>
                        <div className="song-info">
                            <span className="song-info-title">Music</span><br/>
                            <span className="song-info-artist">Radio Junction</span><br/>
                            <span className="song-info-album">Radio</span>
                        </div>
                    </div>
                    <div className="player-wrapper">
                        <i className="fas fa-maximize player-maximize-btn">
                            <MdExpandLess />
                        </i>
                        <i className="fas fa-circle-notch">
                            <FaRedo />
                        </i>
                        <i className="fas fa-caret-left player-move-btn" id="prevBtn">
                            <MdSkipPrevious />
                        </i>
                        <i className="fas fa-play-circle player-state-btn" id="playPauseBtn">
                            <div className="play-circle">
                                <MdPlayCircle />
                            </div>
                            <div className="pause-circle displayhide">
                                <MdPauseCircle />
                            </div>
                        </i>
                        <i className="fas fa-caret-right player-move-btn" id="nextBtn">
                            <MdSkipNext />
                        </i>
                        <i className="fas fa-random">
                            <FaRandom />
                        </i>
                        <i className="fas displayhide player-close">
                            <MdOutlineClose />
                        </i>
                    </div>
                    <div className="volume-wrapper">
                        <i className="fas fa-volume-off valume-off">
                            <MdVolumeDown />
                        </i>
                        <div className="volume-bar">
                            <input readOnly type="range" name="volume-slider" id="volume-slider" min="0" max="1" step="0.1" value="1" />
                            <div className="volume-trail" style={{width:"100%"}}></div>
                        </div>
                        <i className="fas fa-volume-up valume-on">
                            <MdVolumeUp />
                        </i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RadioPlayer;