import React,{useEffect} from 'react';
import { Link } from "react-router-dom";
import '../assets/css/navigation.css';
import { FaHome } from "react-icons/fa";
import { MdVideoLibrary,MdLibraryMusic } from "react-icons/md";
// import { MdGamepad,MdMovie } from "react-icons/md";
import $ from 'jquery';

function menuActive(){
    $(document).on('click','.navigation ul li', function(){
        $(this).parent().find('li').removeClass('active');
        $(this).addClass('active');
    });
    // const getCurrentUrl = window.location.search;
    // $('.navigation ul li').removeClass('active');
    // $(".navigation ul li").each(function(){
    //     let getCurr = $(this).find('a').attr('href');
    //     console.log(getCurr);
    //     if(getCurrentUrl.indexOf(getCurr)) {
    //         $(this).addClass('active');
    //         return false;
    //     }
    // });
}

function Navigation(){
    useEffect(() => {
        setTimeout(function(){
            menuActive();
        }, 900);
    },[]);
    return (
        <div className="navigation">
            <header>
                <nav className="mobile-menu nav nav--icons">
                    <ul>
                        {/* <li>
                            <Link to="/livetv">
                                <div className="menu-icon">
                                    <MdMovie />
                                </div>
                                <span>Movies</span>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/livetv">
                                <div className="menu-icon">
                                    <MdVideoLibrary />
                                </div>
                                <span>Live TV</span>
                            </Link>
                        </li>
                        <li className="circle-menu active">
                            <Link to="/home">
                                <div className="menu-icon">
                                    <FaHome />
                                </div>
                                <span>Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/radio">
                                <div className="menu-icon">
                                    <MdLibraryMusic />
                                </div>
                                <span>Radio</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/games">
                                <div className="menu-icon">
                                    <MdGamepad />
                                </div>
                                <span>Games</span>
                            </Link>
                        </li> */}
                    </ul>
                </nav>
            </header>
        </div>
    );
}

export default Navigation;