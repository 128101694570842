import React,{useEffect} from 'react';
import PageHeader from '../components/PageHeader';
import GameList from '../api/GameList';
import { MdFullscreen,MdFullscreenExit } from "react-icons/md";
import $ from 'jquery';

function GamePlayer(){
    $(document).on('click','.game-content .full-icon', function(){
        $('body').addClass('fullscreen-player');
    });
    $(document).on('click','.game-content .exit-full-icon', function(){
        $('body').removeClass('fullscreen-player');
    });
    useEffect(() => {
        let finalGameUrl;
        const getCurrentUrl = window.location.search;
        const getParams = new URLSearchParams(getCurrentUrl);
        const gameName = getParams.get('name');
        $.each(GameList, function(key, val){
            if(val.game_uri === gameName) {
                finalGameUrl = val.game_link;
                return false
            }
        });
        $('#gamePlayer').html("");
        $('#gamePlayer').html('<iframe src="'+finalGameUrl+'" width="100%" height="430" frameborder="0" allow="accelerometer; autoplay; encrypted-media; picture-in-picture" allow="autoplay=false" allowfullscreen="" mozallowfullscreen="" webkitallowfullscreen="" scrolling="no" style="border:none;"></iframe>');
    },[]);
    return (
        <div className="game-content">
            <PageHeader />
            <div className="fullscreen-icon">
                <i className="full-icon">
                    <MdFullscreen />
                </i>
                <i className="exit-full-icon displayhide">
                    <MdFullscreenExit />
                </i>
            </div>
            <div className="gplayer" id="gamePlayer"></div>
        </div>
    );
}

export default GamePlayer;