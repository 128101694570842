import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SearchResult from '../components/SearchResult';
import '../assets/css/search.css';
import $ from 'jquery';

class Search extends Component {
    componentDidMount() {
        $('body').addClass('search-page');
    }
    componentWillUnmount() {
        $('body').removeClass('search-page');
    }
    render() {
        return (
            <div className="page-layout">
                <PageHeader />
                <div className="page-content">
                    <SearchResult />
                </div>
            </div>
        );
    }
}

export default Search;