const TvChannels = [
    { 
        id: 1,
        category: 'news',
        channel_name: 'Aaj Tak',
        channel_link: 'https://feeds.intoday.in/aajtak/api/master.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 2, 
        category: 'news',
        channel_name: 'NDTV 24x7', 
        channel_link: 'https://ndtv24x7elemarchana.akamaized.net/hls/live/2003678/ndtv24x7/masterp_480p@3.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 3, 
        category: 'news',
        channel_name: 'GNT Live', 
        channel_link: 'https://feeds.intoday.in/gnt/api/master.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 4, 
        category: 'news',
        channel_name: 'India Today', 
        channel_link: 'https://indiatodaylive.akamaized.net/hls/live/2014320/indiatoday/indiatodaylive/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 5, 
        category: 'news',
        channel_name: 'Zee News', 
        channel_link: 'https://d233nwklwv8p4.cloudfront.net/out/v1/860cb318d23a4c64b58c9d625281170c/index_6.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 6, 
        category: 'news',
        channel_name: 'Zee Hinustan', 
        channel_link: 'https://d336l5u76wvq7j.cloudfront.net/out/v1/5990b42d2b7b40bc8e581e90afe6ef94/index_3.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 7, 
        category: 'news',
        channel_name: 'Zee Business', 
        channel_link: 'https://d8gy12azhr71i.cloudfront.net/out/v1/45be109c5b4f44319e882da947377364/index_5.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 8, 
        category: 'movies',
        channel_name: 'Filamchi Bhojpuri', 
        channel_link: 'https://epiconvh.s.llnwi.net/filamchi/master_1024.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 9, 
        category: 'music',
        channel_name: '9XM Music', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:9XM.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 10, 
        category: 'music',
        channel_name: 'Mastiii', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:Mastiii.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 11, 
        category: 'music',
        channel_name: 'Music India', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:Music_India.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 12, 
        category: 'music',
        channel_name: '9X Jalwa', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:9X_Jalwa.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 13, 
        category: 'music',
        channel_name: '9X Jhakaas', 
        channel_link: 'https://pull-3188.5centscdn.com/2a/smil:9X_Jhakaas.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 14, 
        category: 'music',
        channel_name: 'Sangeet Bhojpuri', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:Sangeet_Bhojpuri.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 15, 
        category: 'movies',
        channel_name: 'B4U Kadak', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:B4U_Kadak.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 16, 
        category: 'news',
        channel_name: 'Republic Bharat', 
        channel_link: 'https://pull-3188.5centscdn.com/vizjsj1apjkea3/smil:Republic_Bharat.smil/playlist.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    },
    { 
        id: 17, 
        category: 'news',
        channel_name: 'News24 Live', 
        channel_link: 'https://vidcdn.vidgyor.com/news24-origin/liveabr/news24-origin/live2/chunks.m3u8',
        thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    }
    // { 
    //     id: 3, 
    //     category: 'news',
    //     channel_name: 'CNBC Awaaz', 
    //     channel_link: 'https://cnbcawaaz-lh.akamaihd.net/i/cnbcawaaz_1@174872/index_5_av-p.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    // { 
    //     id: 4, 
    //     category: 'movies',
    //     channel_name: 'BFlix Movies', 
    //     channel_link: 'https://m-c036-j2apps.s.llnwi.net/hls/5045.BFlixMovies.in_480p/index.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    // { 
    //     id: 6, 
    //     category: 'movies',
    //     channel_name: 'Filmy Channel', 
    //     channel_link: 'https://m-c15-j2apps.s.llnwi.net/hls/0079.Filmy.in_288p/index.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    // { 
    //     id: 7, 
    //     category: 'movies',
    //     channel_name: 'Zee Action', 
    //     channel_link: 'http://93.152.174.144:4000/play/zeeaction/index.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    // { 
    //     id: 8, 
    //     category: 'movies',
    //     channel_name: 'Dabangg Bhojpuri', 
    //     channel_link: 'https://m-c01-j2apps.s.llnwi.net/hls/0950.DabanggTV.in_360p/index.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    // { 
    //     id: 9, 
    //     category: 'movies',
    //     channel_name: 'Rishu Movies', 
    //     channel_link: 'https://m-c010-j2apps.s.llnwi.net/hls/8071.RishuMovies.in_270p/index.m3u8',
    //     thumbnail: 'assets/images/tvchannel/thumbnail.jpg'
    // },
    
    
];

export default TvChannels;