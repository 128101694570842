import React from 'react';
import { Link,useNavigate } from "react-router-dom";
import logo from '../assets/images/jhankarplay-logo.png';
import { MdOutlineSearch,MdHome,MdVideoLibrary,MdLibraryMusic } from "react-icons/md";
import { MdFileCopy,MdHelp,MdGamepad,MdClose } from "react-icons/md";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { FaRegFile,FaRegFileAlt } from "react-icons/fa";
import $ from 'jquery';

function PageHeader() {
    const navigate = useNavigate();
    $(document).on('click','.search-box', function(){
        $(this).addClass('open');
        $(this).find('input').focus();
    });
    $(document).on('touch click','.searchlayer', function(){
        $(document).find('.search-box').removeClass('open');
        $(document).find('.search-input').find('input').val('');
    });
    /********nav menu js*******/
    $(document).on('click','.navmenu .menu-icon', function(event){
        event.preventDefault();
        $(this).parent().addClass('open');
    });
    $(document).on('click','.navmenu .menu-close-icon', function(event){
        event.preventDefault();
        $(this).parent().removeClass('open');
    });
    $(document).on('click','.navmenu .overlay', function(event){
        event.preventDefault();
        $(this).parent().parent().removeClass('open');
    });

    // const handleSearch = (event) => {
    //     if (event.key === 'Enter') {
    //         let searchKey = event.target.value;
    //         navigate(`/search?key=`+searchKey);
    //     }
    // }

    const handleSearch = (event) => {
        let searchKeyNew = event.target.value;
        if (event.key === 'Enter') {
            // videoSearch(searchKeyNew);
            navigate(`/search?key=`+searchKeyNew);
            event.currentTarget.blur();
        } else {
            $.ajax({
                type: "POST",
                url: 'https://suggestqueries.google.com/complete/search?hl=en&ds=yt&client=youtube&hjson=t&cp=1',
                dataType: 'jsonp',
                data: $.extend({
                    q: searchKeyNew
                }, {}),
                success: function (data) {
                    // console.log(data);
                    let items = data[1], videoKeyList = "";
                    $.each(items, function (index, element) {
                        // console.log(index);
                        videoKeyList = videoKeyList + '<li data-key="'+element[0]+'">'+element[0]+'</li>';
                    });
                    $("#SearchKeyList").html(videoKeyList);
                }
            });
        }
    }

    $(document).on('click','.search-key-list li', function(){
        let getKeyData = $(this).attr('data-key');
        $(document).find('#search').val(getKeyData);
        $(document).find('.search-key-list').hide();
        navigate(`/search?key=`+getKeyData);
    });

    return (
        <>
            <div className="page-title">
                <div className="navmenu">
                    <div className="menu-icon">
                        <AiOutlineAlignLeft />
                    </div>
                    <div className="menu-close-icon displayhide">
                        <MdClose />
                    </div>
                    <div className="menu-content">
                        <ul>
                            <li>
                                <span className="link-icon">
                                    <MdHome />
                                </span>
                                <Link to="/home" className="navlink">Home</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <MdVideoLibrary />
                                </span>
                                <Link to="/livetv" className="navlink">TV Channels</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <MdLibraryMusic />
                                </span>
                                <Link to="/radio" className="navlink">Radio Channels</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <MdGamepad />
                                </span>
                                <Link to="/games" className="navlink">Gaming Zone</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <MdFileCopy />
                                </span>
                                <Link to="/disclaimer" className="navlink">Disclaimer</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <FaRegFile />
                                </span>
                                <Link to="/terms_conditions" className="navlink">Terms & Conditions</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <FaRegFileAlt />
                                </span>
                                <Link to="/privacy_policy" className="navlink">Privacy Policy</Link>
                            </li>
                            <li>
                                <span className="link-icon">
                                    <MdHelp />
                                </span>
                                <Link to="/contact" className="navlink">Contact Us</Link>
                            </li>
                            {/* <hr className="hidemob" />
                            <li className="hidemob">
                                <span className="link-icon">
                                    <FaTools />
                                </span>
                                <Link to="/tools/voice-to-text" className="navlink">Voice To Text</Link>
                            </li> */}
                        </ul>
                        <div className="overlay"></div>
                    </div>
                </div>
                <Link className="logo" to="/">
                    <img src={logo} alt="Logo" />
                </Link>
                <div className="search-box">
                    <div className="search-input">
                        <input type="text" className="textbox" id="search" placeholder="Search..." onKeyDown={handleSearch} />
                    </div>
                    <div className="search-icon">
                        <MdOutlineSearch />
                    </div>
                    <ul className="search-key-list" id="SearchKeyList"></ul>
                </div>
                <div className="searchlayer"></div>
            </div>
        </>
    );
}

export default PageHeader;