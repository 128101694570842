import React,{useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import '../../node_modules/jquery-touchswipe/jquery.touchSwipe.js';
import '../assets/css/videoplayer.css';
import { MdExpandLess,MdOutlineExpandMore } from "react-icons/md";
// import { MdFullscreen,MdFullscreenExit } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import $ from 'jquery';
import bgLayer from '../assets/images/radio/thumbnail.jpg';
import YouTube from 'react-youtube';

function VideoPlayer() {
    const navigate = useNavigate();
    const [videoIdUrl, setVideoIdUrl] = useState();
    const [videoTitle, setVideoTitle] = useState();
    const [source, setSource] = useState();
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        }
    };

    $(document).on('click','.tvplayer .fullscreen-icon', function(){
        $('body').toggleClass('fullscreen-player');
    });

    $(document).on('click','.videoplayer .player-maximize-btn', function(){
        $('body').removeClass('video-miniplayer');
    });
    $(document).on('click','.videoplayer .back-icon', function(){
        $('body').addClass('video-miniplayer');
    });

    useEffect(() => {
        $(document).on('click','.banner-slider .banner-link', function(event){
            event.stopImmediatePropagation();
            $(document).find('.videoplayer').removeClass('left-closed right-closed');
            $(this).parents('.banner-slider').find('li').removeClass('active');
            $(this).addClass('active');
            let channel_uri = $(this).attr('data-id');
            let channel_name = $(this).find('h4').text();
            $(document).find('.videoplayer').show();
            setVideoIdUrl(channel_uri);
            setVideoTitle(channel_name);
            let source = $(this).attr('data-source');
            setSource(source);
            if(source === "home") {
                $('.player-title .back-icon').hide();
            } else {
                $('.player-title .back-icon').show();
            }
        });
        $(document).on('click','.video-list ul li', function(event){
            event.stopImmediatePropagation();
            $(document).find('.videoplayer').removeClass('left-closed right-closed');
            $(this).parent().find('li').removeClass('active');
            $(this).addClass('active');
            let channel_uri = $(this).attr('data-id');
            let channel_name = $(this).attr('data-title');
            $(document).find('.videoplayer').show();
            setVideoIdUrl(channel_uri);
            setVideoTitle(channel_name);
            let source = $(this).attr('data-source');
            setSource(source);
            if(source === "search") {
                $('.player-title .back-icon').show();
            } else {
                $('.player-title .back-icon').hide();
            }
        });
    }, []);

    function pauseVideo() {
        $(document).find('#videoframe iframe').attr('src',"");
    }

    function closeCurrentPlayer(direction){
        pauseVideo();
        if($('body').hasClass('video-miniplayer')) {
            if(direction === "left") {
                $(document).find('.videoplayer').addClass('left-closed');
            } else {
                $(document).find('.videoplayer').addClass('right-closed');
            }
            setTimeout(function(){
                $(document).find('.videoplayer').hide();
                $(document).find('.video-list li').removeClass('active');
                $(document).find('body').removeClass('video-miniplayer');
            }, 1500);
        } else {
            if(direction === "left") {
                $(document).find('.videoplayer').addClass('left-closed');
            } else {
                $(document).find('.videoplayer').addClass('right-closed');
            }
            setTimeout(function(){
                $(document).find('.videoplayer').hide();
                $(document).find('.video-list li').removeClass('active');
                $(document).find('body').removeClass('video-miniplayer');
            }, 1500);
        }
    }

    $(".videoplayer").swipe({
        //Single swipe handler for left swipes
        swipeLeft:function(event) {
            closeCurrentPlayer('left');
        },
        swipeRight:function(event) {
            closeCurrentPlayer('right');
        },
        swipeUp:function(event) {
            if($('body').hasClass('video-miniplayer')) {
                $(document).find('body').removeClass('video-miniplayer');
            }
        },
        swipeDown:function(event) {
            if(!($('body').hasClass('video-miniplayer'))) {
                $(document).find('body').addClass('video-miniplayer');
            }
        }
    });

    $(document).on('click','.videoplayer-close', function(){
        let checkSource = $(document).find('#getSource').val();
        if(checkSource === "home") {
            closeCurrentPlayer('left');
            navigate(`/home`);
        } else {
            closeCurrentPlayer('left');
        }
    });

    return (
        <div className="videoplayer">
            <input type="hidden" id="getSource" value={source} />
            <div className="background-layer">
                <img src={bgLayer} alt="Background" />
            </div>
            <div className="player-title">
                <div className="back-icon">
                    <MdOutlineExpandMore />
                </div>
                <h1 id="channelName" data-id="0">{videoTitle}</h1>
                {/* <div className="fullscreen-icon">
                    <i className="full-icon">
                        <MdFullscreen />
                    </i>
                    <i className="exit-full-icon displayhide">
                        <MdFullscreenExit />
                    </i>
                </div> */}
                <div className="player-close-icon videoplayer-close">
                    <AiFillCloseCircle />
                </div>
            </div>
            <div className="player-content">
                <div className="playerframe">
                    <span className="maximize-icon player-maximize-btn">
                        <MdExpandLess />
                    </span>
                    <div className="videoframe" id="videoframe">
                        <YouTube videoId={videoIdUrl} opts={opts}></YouTube>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;