import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";

class HomeCardSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 574,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
          };
        return (
            <>
                <Slider className="banner-slider" {...settings}>
                    <div className="slider-item-child">
                        <div className="banner-card">
                            <Link className="banner-link" to="/watch?vid=D2fGtAzEC-Y" data-id="D2fGtAzEC-Y" data-source="home">
                                <img src="https://img.youtube.com/vi/D2fGtAzEC-Y/sddefault.jpg" alt="Banner" />
                                <h4 className="mt-1">Rishtey A Grand Celebration | New Hindi Dubbed Movie 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="slider-item-child">
                        <div className="banner-card">
                            <div className="banner-link" to="/watch?vid=EDE10UUnAzM" data-id="EDE10UUnAzM" data-source="home">
                                <img src="https://img.youtube.com/vi/EDE10UUnAzM/sddefault.jpg" alt="Banner" />
                                <h4 className="mt-1">RIDER (2022) Full Hindi Dubbed Action South Movie</h4>
                            </div>
                        </div>
                    </div>
                    <div className="slider-item-child">
                        <div className="banner-card">
                            <Link className="banner-link" to="/watch?vid=5Y87gFvzEO4" data-id="5Y87gFvzEO4" data-source="home">
                                <img src="https://img.youtube.com/vi/5Y87gFvzEO4/sddefault.jpg" alt="Banner" />
                                <h4 className="mt-1">VASCO THE REBEL (Romantic) 2022 New Released Hindi Dubbed</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="slider-item-child">
                        <div className="banner-card">
                            <Link className="banner-link" to="/watch?vid=oAvNsimUqBM" data-id="oAvNsimUqBM" data-source="home">
                                <img src="https://img.youtube.com/vi/oAvNsimUqBM/sddefault.jpg" alt="Banner" />
                                <h4 className="mt-1">MADHAGAJA (2022) New Released Full Hindi Dubbed South Movie</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="slider-item-child">
                        <div className="banner-card">
                            <Link className="banner-link" to="/watch?vid=NpJ6lnbgi_s" data-id="NpJ6lnbgi_s" data-source="home">
                                <img src="https://img.youtube.com/vi/NpJ6lnbgi_s/sddefault.jpg" alt="Banner" />
                                <h4 className="mt-1">"Check" Latest Hindi Dubbed Full Movie 2022</h4>
                            </Link>
                        </div>
                    </div>
                </Slider>
                <div className="heading-title heading-col2">
                    <h2>TV Channels</h2>
                    <Link to="/livetv" className="viewall-link">View All</Link>
                </div>
                <div className="bg-card-rows">
                    <div className="bg-card-box bg-card-4">
                        <Link className="navlink" to="/livetv?category=music">
                            Music Channels
                        </Link>
                    </div>
                    <div className="bg-card-box bg-card-2">
                        <Link className="navlink" to="/livetv?category=news">
                            News Channels
                        </Link>
                    </div>
                    <div className="bg-card-box bg-card-3">
                        <Link className="navlink" to="/livetv?category=movies">
                            Movies Channels
                        </Link>
                    </div>
                    <div className="bg-card-box">
                        <Link className="navlink" to="/livetv?category=music">
                            Bhojpuri Channels
                        </Link>
                    </div>
                </div>
                <div className="heading-title heading-col2">
                    <h2>Gaming Zone</h2>
                    <Link to="/games" className="viewall-link">View All</Link>
                </div>
                <div className="bg-card-rows">
                    <div className="bg-card-box bg-card-3">
                        <Link className="navlink" to="/game/player?name=menja">
                            Menja 2.0
                        </Link>
                    </div>
                    <div className="bg-card-box">
                        <Link className="navlink" to="/game/player?name=car-racing">
                            Car Racing
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}

export default HomeCardSlider;